<template>
  <v-card dark tile :loading="loading">
    <v-container fluid class="py-5">
      <v-form ref="form" lazy-validation>
        <v-row align="center">
          <!-- Nombre -->
          <v-col class="d-flex" cols="12">
            <v-text-field
              v-model="company.name"
              :counter="50"
              label="Nombre de empresa"
              hint="Nombre de empresa"
              persistent-hint
              required
              solo-inverted
              :rules="[rules.required, rules.counter50]"
            ></v-text-field>
          </v-col>
          <!-- Custom Id -->
          <v-col class="d-flex" cols="12">
            <v-text-field
              v-model="company.customId"
              :counter="15"
              label="Id personalizado"
              hint="Id personalizado (se utilizará para la búsqueda)"
              persistent-hint
              required
              solo-inverted
              :rules="[rules.required, rules.counter15]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-5 mb-0 mx-1">
          <v-spacer></v-spacer>
          <v-btn plain :to="{ name: 'Companies' }"> Cancelar </v-btn>
          <v-btn @click="save" color="#0049a0"> Guardar </v-btn>
        </v-row>
      </v-form>
    </v-container>

    <v-overlay :value="uploading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
export default {
  name: "Company",
  data: (vm) => ({
    company: {
      name: "",
      customId: "",
    },
    rules: {
      required: (value) => !!value || "Requerido.",
      counter15: (value) => value.length <= 15 || "15 carácteres máximos",
      counter50: (value) => value.length <= 50 || "50 carácteres máximos",
    },
  }),
  created() {
    this.initData();
  },
  computed: {
    loading() {
      return this.$store.state.loadingCompanies;
    },
    uploading() {
      return this.$store.state.uploadingCompany;
    },
  },
  watch: {
    loading(v) {
      if (v == false) {
        this.company = this.$store.state.selectedCompany;
      }
    },
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        let data = {};
        data.company = this.company;
        data.companyId = this.$route.params.companyId;
        this.$store.dispatch("updateCompany", data);
      }
    },
    initData() {
      if (!!this.$route.params.companyId) {
        this.$store.dispatch("selectCompany", this.$route.params.companyId);
      }
    },
  },
};
</script>

<style>
</style>